.App {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: #ececec;
  padding: 0;
  margin: 0;
}

.game {
  justify-content: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  margin: 0;
}

.gameBoard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* width: ; */
  margin: 0;
}

.row {
  display: flex;
  justify-content: center;
  width: fit-content;
}

header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 16px;
  border-bottom: 1px solid;
}

.menu-left {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 70px;
  justify-content: flex-start;
}

.menu-right {
  display: flex;
  width: 70px;
  justify-content: flex-end;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row.active {
  background: #cccccc;
}

.cell {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  padding: 5px;
  margin: 2px;
}

.correct {
  background: #4ade80 !important;
}

.present {
  background: #fbbf24 !important;
}

.not-found {
  background: #cccccc !important;
}
